<template>
  <g>
    <svg:style>
      .cls-10, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#inox-gradient-2);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#inox-gradient-3);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }

      .cls-10 {
        fill: url(#inox-gradient-4);
      }

      .cls-11 {
        fill: url(#glass-pattern);
      }

      .cls-12 {
        fill: url(#inox-gradient-5);
      }

      .cls-13 {
        fill: url(#glass-pattern);
      }

      .cls-14 {
        fill: url(#inox-gradient-6);
      }

      .cls-15 {
        fill: url(#glass-pattern);
      }
    </svg:style>

    <linearGradient id="inox-gradient"
      :x1="doorLeftWidth1 + 42.31"
      :y1="doorTopHeight1 + 71.49"
      :x2="doorLeftWidth1 + 63.24"
      :y2="doorTopHeight1 + 71.49"
      xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient"
      :x1="doorLeftWidth1 + 52.77"
      :y1="doorTopHeight1 + 98.71"
      :x2="doorLeftWidth1 + 52.77"
      :y2="doorTopHeight1 + 44.28"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 77.17"
      :y1="doorTopHeight1 + 71.49"
      :x2="doorLeftWidth1 + 98.1"
      :y2="doorTopHeight1 + 71.49"
      xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-2"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 87.63"
      :y1="doorTopHeight1 + 98.71"
      :x2="doorLeftWidth1 + 87.63"
      :y2="doorTopHeight1 + 44.28"
      xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-3"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 42.31"
      :y1="doorTopHeight1 + 148.19"
      :x2="doorLeftWidth1 + 63.24"
      :y2="doorTopHeight1 + 148.19"
      xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-3"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 52.77"
      :y1="doorTopHeight1 + 175.41"
      :x2="doorLeftWidth1 + 52.77"
      :y2="doorTopHeight1 + 120.98"
      xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-4"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 77.17"
      :y1="doorTopHeight1 + 148.19"
      :x2="doorLeftWidth1 + 98.1"
      :y2="doorTopHeight1 + 148.19"
      xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-4"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 87.63"
      :y1="doorTopHeight1 + 175.41"
      :x2="doorLeftWidth1 + 87.63"
      :y2="doorTopHeight1 + 120.98"
      xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-5"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 42.31"
      :y1="doorTopHeight1 + 224.69"
      :x2="doorLeftWidth1 + 63.24"
      :y2="doorTopHeight1 + 224.69"
      xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-5"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 52.77"
      :y1="doorTopHeight1 + 251.91"
      :x2="doorLeftWidth1 + 52.77"
      :y2="doorTopHeight1 + 197.48"
      xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-6"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 77.17"
      :y1="doorTopHeight1 + 224.69"
      :x2="doorLeftWidth1 + 98.1"
      :y2="doorTopHeight1 + 224.69"
      xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-6"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 87.63"
      :y1="doorTopHeight1 + 251.91"
      :x2="doorLeftWidth1 + 87.63"
      :y2="doorTopHeight1 + 197.48"
      xlink:href="#glass-gradient"/>


    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="c02">

      <polyline id="inox"
                v-if="showInox"
                class="cls-4"
                :points="`${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 40.09} ${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 102.89} ${doorLeftWidth1 + 63.24} ${doorTopHeight1 + 102.89} ${doorLeftWidth1 + 63.24} ${doorTopHeight1 + 40.09} ${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 40.09}`"/>
      <polyline id="glass"
                class="cls-5"
                :points="`${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 44.28} ${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 98.71} ${doorLeftWidth1 + 59.05} ${doorTopHeight1 + 98.71} ${doorLeftWidth1 + 59.05} ${doorTopHeight1 + 44.28} ${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 44.28}`"/>
      <polyline id="inox-2"
                v-if="showInox"
                data-name="inox"
                class="cls-6"
                :points="`${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 40.09} ${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 102.89} ${doorLeftWidth1 + 98.1} ${doorTopHeight1 + 102.89} ${doorLeftWidth1 + 98.1} ${doorTopHeight1 + 40.09} ${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 40.09}`"/>
      <polyline id="glass-2"
                data-name="glass"
                class="cls-7"
                :points="`${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 44.28} ${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 98.71} ${doorLeftWidth1 + 93.91} ${doorTopHeight1 + 98.71} ${doorLeftWidth1 + 93.91} ${doorTopHeight1 + 44.28} ${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 44.28}`"/>
      <polyline id="inox-3"
                v-if="showInox"
                data-name="inox"
                class="cls-8"
                :points="`${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 116.79} ${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 179.6} ${doorLeftWidth1 + 63.24} ${doorTopHeight1 + 179.6} ${doorLeftWidth1 + 63.24} ${doorTopHeight1 + 116.79} ${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 116.79}`"/>
      <polyline id="glass-3"
                data-name="glass"
                class="cls-9"
                :points="`${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 120.98} ${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 175.41} ${doorLeftWidth1 + 59.05} ${doorTopHeight1 + 175.41} ${doorLeftWidth1 + 59.05} ${doorTopHeight1 + 120.98} ${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 120.98}`"/>
      <polyline id="inox-4"
                v-if="showInox"
                data-name="inox"
                class="cls-10"
                :points="`${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 116.79} ${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 179.6} ${doorLeftWidth1 + 98.1} ${doorTopHeight1 + 179.6} ${doorLeftWidth1 + 98.1} ${doorTopHeight1 + 116.79} ${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 116.79}`"/>
      <polyline id="glass-4"
                data-name="glass"
                class="cls-11"
                :points="`${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 120.98} ${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 175.41} ${doorLeftWidth1 + 93.91} ${doorTopHeight1 + 175.41} ${doorLeftWidth1 + 93.91} ${doorTopHeight1 + 120.98} ${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 120.98}`"/>
      <polyline id="inox-5"
                v-if="showInox"
                data-name="inox"
                class="cls-12"
                :points="`${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 193.29} ${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 256.1} ${doorLeftWidth1 + 63.24} ${doorTopHeight1 + 256.1} ${doorLeftWidth1 + 63.24} ${doorTopHeight1 + 193.29} ${doorLeftWidth1 + 42.31} ${doorTopHeight1 + 193.29}`"/>
      <polyline id="glass-5"
                data-name="glass"
                class="cls-13"
                :points="`${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 197.48} ${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 251.91} ${doorLeftWidth1 + 59.05} ${doorTopHeight1 + 251.91} ${doorLeftWidth1 + 59.05} ${doorTopHeight1 + 197.48} ${doorLeftWidth1 + 46.49} ${doorTopHeight1 + 197.48}`"/>
      <polyline id="inox-6"
                v-if="showInox"
                data-name="inox"
                class="cls-14"
                :points="`${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 193.29} ${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 256.1} ${doorLeftWidth1 + 98.1} ${doorTopHeight1 + 256.1} ${doorLeftWidth1 + 98.1} ${doorTopHeight1 + 193.29} ${doorLeftWidth1 + 77.17} ${doorTopHeight1 + 193.29}`"/>
      <polyline id="glass-6"
                data-name="glass"
                class="cls-15"
                :points="`${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 197.48} ${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 251.91} ${doorLeftWidth1 + 93.91} ${doorTopHeight1 + 251.91} ${doorLeftWidth1 + 93.91} ${doorTopHeight1 + 197.48} ${doorLeftWidth1 + 81.35} ${doorTopHeight1 + 197.48}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
